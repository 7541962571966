import React, { useContext } from "react";
import { MediaAuthContext } from "../../../../store/context/media-auth-context";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../helpers";
import "./index.scss";

const StandardFormPageBlock = (props) => {
  const {
    buttonTextFirstState,
    buttonTextSecondState,
    customClass,
    emailValue,
    isLoading,
    onEmailChange,
    onError,
    onKey,
    onSubmit,
    placeholder,
    redirectUrl,
    title,
    formOpacity
  } = props;
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  return (
    <div className={getTrueClasses("StandardFormPageBlock", customClass)}  style={{ opacity: formOpacity }}>
      <div className="container">
        <div className="StandardFormPageBlock-text">
          <div className="StandardFormPageBlock__title">{title}</div>
          {isAuth ? null : (
            <div className="StandardFormPageBlock__cardInfo">
              No credit card required
            </div>
          )}
        </div>
        {isAuth ? (
          <a
            href={redirectUrl}
            target="_blank"
            className="StandardFormPageBlock-secondStateButton"
            rel="nofollow"
          >
            {buttonTextSecondState}
          </a>
        ) : (
          <div className="StandardFormPageBlock-formContainer">
            <form className="StandardFormPageBlock-form">
              <input
                className={getTrueClasses(
                  "StandardFormPageBlock-form__input",
                  onError && "StandardFormPageBlock-form__input_alert"
                )}
                type="text"
                placeholder={onError || placeholder || "example@gmail.com"}
                value={emailValue}
                onChange={onEmailChange}
                onKeyDown={onKey}
              />
              <button
                onClick={onSubmit}
                className="StandardFormPageBlock-form__button"
                disabled={isLoading}
              >
                {buttonTextFirstState}
              </button>
            </form>
            <div className="StandardFormPageBlock__agreeText">
              By entering your email, you agree to receive marketing emails from
              AMZScout
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

StandardFormPageBlock.propTypes = {
  buttonTextFirstState: PropTypes.string.isRequired,
  buttonTextSecondState: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  emailValue: PropTypes.string,
  isLoading: PropTypes.bool,
  onEmailChange: PropTypes.func,
  onError: PropTypes.string,
  onKey: PropTypes.func,
  onSubmit: PropTypes.func,
  redirectUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default StandardFormPageBlock;
