export const ERRORS = {
  URL_ERRORS: {
    ERROR_PARSING_URL_PARAMETERS: "ERROR PARSING URL PARAMETERS:",
  },
  EVENT_ERRORS: {
    ERROR_SENDING_EVENT: "ERROR_SENDING_EVENT:",
  },
  IMAGE_ERRORS: {
    UNDEFINED_IMAGE: "UNDEFINED_IMAGE",
  },
};
