import React, { useContext } from "react";
import { MediaAuthContext } from "../../../../store/context/media-auth-context";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../helpers";
import "./index.scss";
import SecondStateButton from "./SecondStateButton";

const StandardFormIntroBlock = (props) => {
  const {
    buttonTextFirstState,
    buttonTextSecondState,
    customClass,
    emailValue,
    isLoading,
    onEmailChange,
    onError,
    onKey,
    onSubmit,
    placeholder,
    redirectUrl,
    redirectUrlSecondStateButton,
    formOpacity,
  } = props;
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  return (
    <div className={getTrueClasses("StandardFormIntroBlock", customClass)}  style={{ opacity: formOpacity }}>
      {isAuth ? (
        <SecondStateButton
          redirectUrlSecondStateButton={redirectUrlSecondStateButton}
          buttonTextSecondState={buttonTextSecondState}
          redirectUrl={redirectUrl}
        />
      ) : (
        <div className="StandardFormIntroBlock-container">
          <p className="StandardFormIntroBlock__cardInfo">
            No credit card required
          </p>
          <form className="StandardFormIntroBlock-form">
            <input
              className={getTrueClasses(
                "StandardFormIntroBlock-form__input",
                onError && "StandardFormIntroBlock-form__input_alert"
              )}
              type="text"
              placeholder={
                onError ||
                placeholder ||
                "Enter your email to start your FREE TRIAL!"
              }
              value={emailValue}
              onChange={onEmailChange}
              onKeyDown={onKey}
            />
            <button
              onClick={onSubmit}
              className="StandardFormIntroBlock-form__button"
              disabled={isLoading}
            >
              {buttonTextFirstState}
            </button>
          </form>
          <p className="StandardFormIntroBlock__agreeText">
            <span>No credit card required</span> By entering your email, you
            agree to receive marketing emails from AMZScout
          </p>
        </div>
      )}
    </div>
  );
};

StandardFormIntroBlock.propTypes = {
  buttonTextFirstState: PropTypes.string.isRequired,
  buttonTextSecondState: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  emailValue: PropTypes.string,
  formOpacity: PropTypes.number,
  isLoading: PropTypes.bool,
  onClickSecondStateButton: PropTypes.func,
  onEmailChange: PropTypes.func,
  onError: PropTypes.string,
  onKey: PropTypes.func,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  redirectUrl: PropTypes.string,
  redirectUrlSecondStateButton: PropTypes.string,
};

export default StandardFormIntroBlock;
