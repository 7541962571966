import React, { Component } from "react";
import PropTypes from "prop-types";

class Localization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "us",
      group: [
        "US",
        "UM",
        "CA",
        "CH",
        "BR",
        "MX",
        "AR",
        "CO",
        "CL",
        "PE",
        "EC",
        "VE",
        "DO",
        "GT",
        "PA",
        "CR",
        "UY",
        "BO",
        "PY",
        "SV",
        "HN",
        "TT",
        "JM",
        "BS",
        "NI",
        "HT",
        "BB",
        "SR",
        "GY",
        "BZ",
        "LC",
        "AG",
        "GD",
        "KN",
        "VC",
        "DM",
        "RU",
        "AZ",
        "AM",
        "BY",
        "KZ",
        "KG",
        "MD",
        "TJ",
        "UZ",
        "TM",
        "GE",
        "UA",
        "CN",
      ],
      data: {
        footer: {
          us: {
            rights: [
              `© AMZScout. All rights reserved 2016-${process.env.YEAR}`,
            ],
          },
          eu: {
            rights: [
              "AMZScout Corp., 1735 Market Street, Suite 3750, Philadelphia, Pennsylvania 19103",
              "Growthco DMCC, Unit No: BA402, DMCC Business Centre, Level No 1, Jewellery & Gemplex 3, Dubai",
            ],
          },
        },
      },
    };
  }

  updateCurrent = (code) => {
    const localizationSwitch = process.env.LOCALIZATION_SWITCH === "true";
    // const isAboutPage = window.location.pathname.includes("about");
    // const locale = this.state.group.includes(code) || isAboutPage ? 'us' : 'eu'
    const locale = this.state.group.includes(code) ? "us" : "eu";
    this.setState({
      current: localizationSwitch ? locale : "us",
    });
    this.props.setCurrentLocale(locale);
  };

  getLocation = () => {
    if (window.countryCode) {
      this.updateCurrent(window.countryCode);
    } else {
      window.setTimeout(() => {
        this.getLocation();
      }, 100);
    }
  };

  componentDidMount() {
    this.getLocation();
  }

  render() {
    return (
      <>
        {React.cloneElement(this.props.children, {
          localization: this.state.data[this.props.path][this.state.current],
          currentLocaleGroup: this.state.current,
        })}
      </>
    );
  }
}

Localization.propTypes = {
  path: PropTypes.string.isRequired,
};

export default Localization;
