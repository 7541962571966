import React from "react";
import PROINSTALL_CONTENT from "../../common/ProInstall/index.content";
import Screen from "../../common/ProInstall/Screen";
import ExtendedScreen from "../../common/ProInstall/ExtendedScreen";
import TrackedLink from "../../Analytics/TrackedLink";
import "./index.scss";

const ProInstall = () => {
  const { GO_TO, ADD, PRO } = PROINSTALL_CONTENT;
  const url =
    "https://chrome.google.com/webstore/detail/amazon-product-finder-amz/njopapoodmifmcogpingplfphojnfeea?utm_source=site&utm_medium=amzscout&utm_campaign=stock_stats_lp";
  return (
    <section className="PgSS-PI">
      <div className="container">
        <h2 className="PgSS-PI__title">
          Do you need more information about Amazon products and competition?
        </h2>
        <p className="PgSS-PI__subtitle">
          Install Stock Stats on steroids - AMZScout PRO Extension in 3 clicks!
          Get a detailed overview of the niche and its potential profitability
        </p>
        <div className="PgSS-PI__list PgSS-PI-list">
          <div className="PgSS-PI-list__item">
            <Screen screenshot={GO_TO} classes="PgSS-PI-list__img" />
            <p className="PgSS-PI-list__num">01.</p>
            <p className="PgSS-PI-list__text PgSS-PI-list-text">
              Go to the{" "}
              <TrackedLink
                category="Index"
                action="CTA"
                target
                class="PgSS-PI-list-text__link"
                label="PRO Extension for Chrome"
                path={url}
              >
                PRO Extension
              </TrackedLink>{" "}
              page in the Chrome Web Store
            </p>
            <TrackedLink
              category="Index"
              action="CTA"
              target
              class="PgSS-PI-list__btn"
              label="PRO Extension for Chrome"
              path={url}
            >
              Click here
            </TrackedLink>
          </div>
          <div className="PgSS-PI-list__item">
            <Screen screenshot={ADD} classes="PgSS-PI-list__img" />
            <p className="PgSS-PI-list__num">02.</p>
            <p className="PgSS-PI-list__text">
              Click the “Add” button and wait 5 seconds
            </p>
          </div>
          <div className="PgSS-PI-list__item">
            <ExtendedScreen screenshot={PRO} classes="PgSS-PI-list__img" />
            <p className="PgSS-PI-list__num">03.</p>
            <p className="PgSS-PI-list__text">
              AMZScout PRO Extension is downloaded to your Chrome or Edge
              browser - browse products on the Amazon site and get detailed
              analytics right on the search page
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProInstall;
