import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const SecondStateButton = (props) => {
  const { redirectUrl, buttonTextSecondState, redirectUrlSecondStateButton } =
    props;

  return (
    <a
      href={redirectUrlSecondStateButton || redirectUrl}
      target="_blank"
      className="StandardFormIntroBlock-secondStateButton"
      rel="nofollow"
    >
      {buttonTextSecondState}
    </a>
  );
};

SecondStateButton.propTypes = {
  redirectUrl: PropTypes.string,
  redirectUrlSecondStateButton: PropTypes.string,
  buttonTextSecondState: PropTypes.string.isRequired,
};

export default SecondStateButton;
